import React from 'react';
import addToMailchimp from 'gatsby-plugin-mailchimp';
import './style.css';

export default class MailChimpSignup extends React.Component {
    state = {
        FNAME: null,
        LNAME: null,
        email: null,
    }

    _handleChange = (e) => {
        console.log({
            [`${e.target.name}`]: e.target.value,
        });
        this.setState({
            [`${e.target.name}`]: e.target.value,
        });
    }

    _handleSubmit = (e) => {
        e.preventDefault();

        console.log('submit', this.state);

        addToMailchimp(this.state.email, this.state)
            .then(({ msg, result }) => {
                //console.log('msg', `${result}: ${msg}`);

                if (result !== 'success') {
                    throw msg;
                }
                alert(msg);
            })
            .catch((err) => {
                console.log('err', err);
                alert(err);
            });
    }

    render() {
        return (
            <div id="newsletter" className="container p-3">
                <h3 className="text-center mt-1 center titling">E-NEWSLETTER</h3>
                <p className="text-center">Sign up for updates and events</p>
                <div className="m-3 mb-5 center">
                    <form className="" id="signup" onSubmit={this._handleSubmit}>
                    <div className="row">
                    <div className="col-12 col-md-3">
                    <input
                        type="text"
                        onChange={this._handleChange}
                        placeholder="First name"
                        name="FNAME"
                    />
                    </div>
                    <div className="col-12 col-md-3">
                    <input
                        type="text"
                        onChange={this._handleChange}
                        placeholder="Last name"
                        name="LNAME"
                    />
                    </div>
                    <div className="col-12 col-md-3">
                    <input
                        type="email"
                        onChange={this._handleChange}
                        placeholder="email"
                        name="email"
                    />
                    </div>
                    <div className="col-12 col-md-3">
                    <input className="_send" type="submit" />
                    </div>
                    </div>

                    </form>
                </div>
            </div>
        );
    }
}
